import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from './config';
import { Typography } from '@material-ui/core';

function DocViewBetaTerm() {
    const [termID, setTermID] = useState(null);

    useEffect(() => {
        getTermID();
    }, []);

    const getTermID = () => {
        axios.get(`${API_URL}/admission/getTermID`)
            .then(response => {
                const fetchedTermID = response.data[0]; 
                setTermID(fetchedTermID && fetchedTermID.TermID); 
            })
            .catch(error => {
                console.error('Error fetching term ID:', error);
            });
    };

    return (
        <div>
            <Typography variant="h6">TERM ID: {termID}</Typography> 
        </div>
    );
}

export default DocViewBetaTerm;

import React from "react";
import {Route} from "react-router-dom";

import "./App.css";
import {Grid,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import Header from "./components/Header";
import DocView from "./components/DocView";
// import DocViewMenu from './components/DocViewMenu';
import Rankings from "./components/Rankings";
import AppStatusChecker from "./components/AppStatusChecker";
import ResultChecker from "./components/ResultChecker";
import ResultsReleasedNotice from "./components/ResultsReleasedNotice";
import RegistrationForm from "./components/RegistrationForm";
// import RegistrationForm from "./components/v2/RegistrationForm";
import LandingPage from "./components/v2/LandingPage";
import Undergraduate from "./components/v2/Undergraduate";
import PreRegQuestions from "./components/v2/PreRegQuestions";
import DataPrivacyAgreement from "./components/v2/DataPrivacyAgreement";
import ForeignApplicantDetails from "./components/v2/ForeignApplicantDetails";

import TestScheduleSelector from "./components/v2/TestScheduleSelector";


// react pdf
import {DownloadNOAP, ViewNOAP} from "./reports/NOAP";
import DocViewBeta from "./components/DocViewBeta";
import DocViewBetaTerm from "./components/DocViewBetaTerm";
import RankingsBeta from "./components/RankingsBeta";

const App = () => {
   const style = makeStyles((theme) => ({
      root: {
         width: "100%",
         background: "transparent",
      },
      helloThereStyle: {
         fontStyle: "oblique",
      },
   }));

   const {root, helloThereStyle} = style();

   // const data = {
   //    date: new Date().toLocaleString('en-US', {
   //       day: 'numeric', // numeric, 2-digit
   //       year: 'numeric', // numeric, 2-digit
   //       month: 'long', // numeric, 2-digit, long, short, narrow
   //    }),
   //    name: "Juan Dela Cruz",
   //    college: "Information and Computing",
   //    campus: "Obrero",
   //    program: "Bachelor of Science in Computer Science major in Data Science",
   //    yearLevel: "1st year",
   //    semester: "1st Semester",
   //    academicYear: "2021-2022",
   // };
   // return (
   //    <div>
   //       <DownloadNOAP data={data}/>
   //       <ViewNOAP data={data}/>
   //    </div>
   // );

   return (
      <Grid container direction="column">
         <Route path="/" exact component={LandingPage}/>
         <Header/>
         <Route path="/regForm" exact component={RegistrationForm} />
         {/* <Route path="/" exact component={RegistrationForm}/> */}
         
         <Route path="/newLanding" exact component={LandingPage}/>
         {/* <Route path="/" exact component={ResultsReleasedNotice}/> */}
         {/* <Route path="/" exact component={DocView}/> */}
         {/* <DocViewMenu /> */}
         <Route path="/docView" exact component={DocView}/>
         <Route path="/docViewbeta" exact component={DocViewBeta}/>
         <Route path="/docViewbetaterm" exact component={DocViewBetaTerm}/>
         <Route path="/rankingsbeta" exact component={RankingsBeta}/>
         <Route path="/rankings" exact component={Rankings}/>
         {/* <Route path="/results" exact component={ResultChecker}/> */}
         <Route path="/checkStatus" exact component={AppStatusChecker}/>

         <Route path="/preRegQuestions" exact component={PreRegQuestions}/>
         <Route path="/dataPrivacyAgreement" exact component={DataPrivacyAgreement}/>
         <Route path="/foreignApplicantDetails" exact component={ForeignApplicantDetails}/>

         <Route path="/testSchedule" exact component={TestScheduleSelector}/>
      </Grid>
   );
};

export default App;
